import {
  ContentBlock,
  ContentState,
  EditorState,
  genKey,
  Modifier,
  SelectionState,
  Editor,
  BlockMap,
} from 'draft-js'
import {isEmpty, camelCase} from 'lodash'
import {table} from './BlockTableRender'
import {Image} from './BlockImageRender'
import {Map, OrderedSet} from 'immutable'
import {RichTextEditorValue, TABLE_BLOCK_TYPE} from '../RichTextEditor'
export const getBlockRendererFn = (
  editor: Editor | null,
  block: ContentBlock | null,
  editorState: EditorState,
  onChange: (value: EditorState) => void
) => {
  if (block) {
    const type = block.getType()

    switch (type) {
      case 'atomic':
        return {
          component: Image,
          editable: false,
          props: {
            editor,
            editorState,
            onChange,
          },
        }
      case TABLE_BLOCK_TYPE:
        return {
          component: table,
          editable: true,
          props: {
            editor,
          },
        }

      default:
        return null
    }
  }
  // If it's not a table block, use the default block renderer
  return null
}

export const insertTable = ({
  editorState,
  rows,
  columns,
  onChange,
}: {
  editorState: EditorState
  rows: number
  columns: number
  onChange: (value: RichTextEditorValue) => void
}) => {
  let selection = editorState.getSelection()

  // don't insert a table within a table
  if (
    editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey()).getType() ===
    TABLE_BLOCK_TYPE
  ) {
    return null
  }

  const defaultCellStyle = {
    border: '1px solid #ccc',
    padding: '10px',
  }
  const cols = Array(columns).fill(1)
  const tableShape = Array(rows)
    .fill(cols)
    .map((row) => row.map(() => ({element: 'td', style: {...defaultCellStyle}, key: genKey()})))

  const tableKey = genKey()
  const newBlocks: ContentBlock[] = []
  tableShape.forEach((row, i) => {
    row.forEach((_: any, j: number) => {
      let data = Map<any>({
        tableKey,
        tablePosition: `${tableKey}-${i}-${j}`,
        'text-align': 'center',
      })
      if (i === 0 && j === 0) {
        data = data
          .set('tableShape', tableShape)
          .set('tableStyle', {
            'border-collapse': 'collapse',
            margin: '15px 0',
            width: '100%',
            'text-align': 'center',
          })
          .set('rowStyle', [])
      }
      const newBlock = new ContentBlock({key: genKey(), type: TABLE_BLOCK_TYPE, text: ' ', data})

      newBlocks.push(newBlock)
    })
  })

  const selectionKey = selection.getAnchorKey()
  let contentState = editorState.getCurrentContent()
  contentState = Modifier.splitBlock(contentState, selection)
  const blockArray = contentState.getBlocksAsArray()
  const currBlock = contentState.getBlockForKey(selectionKey)
  const index = blockArray.findIndex((block) => block === currBlock)
  const isEnd = index === blockArray.length - 1
  if (blockArray[index]?.getType() === TABLE_BLOCK_TYPE) {
    newBlocks.unshift(new ContentBlock({key: genKey()}))
  }
  if (blockArray[index + 1]?.getType() === TABLE_BLOCK_TYPE) {
    newBlocks.push(new ContentBlock({key: genKey()}))
  }
  blockArray.splice(index + 1, 0, ...newBlocks)
  if (isEnd) {
    blockArray.push(new ContentBlock({key: genKey()}))
  }

  const entityMap = contentState.getEntityMap()
  contentState = ContentState.createFromBlockArray(blockArray, entityMap)
  let newEditorState = EditorState.push(editorState, contentState, 'insert-fragment')
  const key = newBlocks[0].getKey()
  selection = SelectionState.createEmpty(key)

  newEditorState = EditorState.acceptSelection(newEditorState, selection)
  onChange(newEditorState)
}

function convertStyleStringToObject(style = '', data = {}) {
  if (!style) {
    return null
  }
  return style
    .split(';')
    .filter((s) => s.includes(':'))
    .map((s) => s.split(':'))
    .reduce((map: any, s: any) => {
      const key = s.shift().trim()
      const val = s.join(':').trim()
      map[key] = val
      return map
    }, data)
}

let tableKey: string = ''
export const stateFromHtmlOptions = {
  // collect block level metadata
  customBlockFn: (element: any) => {
    const style = element.getAttribute('style') || ''
    const className = element.getAttribute('class') || ''
    let data = convertStyleStringToObject(style) || {}
    data = className
      .split(' ')
      .filter((c: any) => c.length)
      .reduce((map: any, c: any) => {
        const key = c.includes('depth') ? 'depth' : c
        const val = key === 'depth' ? +c.slice(5) : 'class'
        map[key] = val
        return map
      }, data)
    // identify lists that were pasted in from another source rather than created natively in the editor. These get handled as a custom block type.
    if (
      element.tagName === 'LI' &&
      (element?.parentNode?.getAttribute('start') || element.style.listStyleType !== 'none') &&
      !element.className
        .split(' ')
        .find((c: string) => ['ordered-list-item', 'unordered-list-item'].includes(c))
    ) {
      const listType = element?.parentNode?.tagName === 'UL' ? 'ul' : 'ol'
      if (element?.parentNode?.firstElementChild === element) {
        data.listStyles = convertStyleStringToObject(
          element.parentNode.getAttribute('style') ?? 'margin-left: 36pt;'
        )
        data.listStart =
          element.getAttribute('start') ??
          element.parentNode.getAttribute('start') ??
          (listType === 'ul' ? 0 : 1)
        let start = data.listStart
        for (const child of element?.parentNode.children) {
          if (listType === 'ul') {
            child.setAttribute('start', 0)
          } else {
            child.setAttribute('start', start++)
          }
        }
      } else {
        data.listStart = element.getAttribute('start')
      }
      data['list-style-type'] =
        element.style.listStyleType || (listType === 'ul' ? 'disc' : 'decimal')
      return {type: 'pasted-list-item', data}
    }

    if (element.firstChild && element.firstChild.tagName === 'IMG') {
      let style = element.firstChild.getAttribute('style')
      style = convertStyleStringToObject(style)
      data = {
        ...data,
        ...(style && {imgStyle: Map(style)}),
      }
      return {type: 'atomic', data}
    }

    if (/break-after:|break-before:/.test(element.style.cssText)) {
      return {type: 'page-break', data}
    }

    if ((element.innerText || '').startsWith('---hr---')) {
      return {type: 'horizontal-rule', data}
    }
    if (['TD', 'TH'].includes(element.tagName)) {
      /**
       * To preserve tables when converting html into Draft block types, we store the full
       * table specifications with the first "cell", and save the table position for the others
       */
      const tableEl = element.closest('table')
      const tHeadEl = element.closest('thead') ?? tableEl.querySelector('thead')
      const tBodyEl = element.closest('tbody') ?? tableEl.querySelector('tbody')
      const tableRows = tableEl.querySelectorAll('tr')
      // But if this table has a nested table within it
      // don't render the outer table or Draft-js will crash
      if (tableEl.querySelector('table')) {
        return {type: 'unstyled', data}
      }

      // empty elements get ignored and can break a table, replace unrendered characters,
      // ensure at minimum there is an non-breaking space
      if (isEmpty(element.textContent.replace(/\s/g, ''))) {
        element.innerHTML = '&nbsp;'
      }

      const prevCell = element.previousElementSibling
      const row = element.parentNode
      const prevRow = row.previousElementSibling
      // Check if this is not the first cell in the table, if it's not then we traverse the table
      // structure just far enough to get the cell's position and store it in the data used to create
      // the corresponding Draft block
      if (prevCell || prevRow || (tHeadEl && [tableEl, tBodyEl].includes(row.parentNode))) {
        let found = false
        for (let i = 0, rows = tableRows, rowCount = rows.length; i < rowCount; i++) {
          for (let j = 0, cells = rows[i].children, colCount = cells.length; j < colCount; j++) {
            if (cells[j] === element) {
              data.tableKey = tableKey
              data.tablePosition = `${tableKey}-${i}-${j}`
              data.colspan = cells[j].getAttribute('colspan')
              data.rowspan = cells[j].getAttribute('rowspan')
              found = true
              break
            }
          }
          if (found) {
            break
          }
        }
        return {type: 'table', data}
      }
      // Only the first cell in the table will go through the processing below, so the Draft block
      // created for it will have all the necessary data to render the empty table structure into
      // which we render the rest of the table blocks.
      const colgroup = tableEl.querySelector('colgroup')
      const tableShape: any[] = []
      tableKey = genKey()
      data.tableKey = tableKey
      data.tablePosition = `${tableKey}-0-0`
      data.tableStyle = convertStyleStringToObject(tableEl.getAttribute('style')) || {
        margin: '15px 0',
        width: '100%',
      }
      data.tableStyle['border-collapse'] = 'collapse'
      for (let i = 0, rows = tableRows, rowCount = rows.length; i < rowCount; i++) {
        tableShape.push([])
        const defaultStyle: Record<string, any> = {}
        if (i === 0) {
          if (element.tagName === 'TH') {
            defaultStyle['background-color'] = 'rgba(240, 240, 240, 0.8)'
          }
          data.rowStyle = [
            convertStyleStringToObject(rows[i].getAttribute('style')) || defaultStyle,
          ]
        } else {
          data.rowStyle.push(
            convertStyleStringToObject(rows[i].getAttribute('style')) || defaultStyle
          )
        }
        for (let j = 0, cells = rows[i].children, colCount = cells.length; j < colCount; j++) {
          const defaultStyle = {
            border: '1px solid rgba(0, 0, 0, 0.2)',
            padding: '6px',
            'text-align': 'center',
            'font-weight': '',
          }
          if (cells[j].tagName === 'TH') {
            defaultStyle['font-weight'] = 'bold'
          }
          const cellStyle =
            convertStyleStringToObject(cells[j].getAttribute('style')) || defaultStyle
          tableShape[i][j] = {
            element: cells[j].tagName === 'TD' ? 'td' : 'th',
            style: cellStyle,
            colspan: cells[j].getAttribute('colspan'),
            rowspan: cells[j].getAttribute('rowspan'),
          } as never
        }
      }

      data.tableShape = tableShape
      data.tableColgroup = colgroup?.outerHTML
      return {type: 'table', data}
    }
    return {data}
  },

  // collect inline style data - inline type elements are passed through this function (span, img, a, etc.)
  customInlineFn: (element: any, {Style, Entity}: any) => {
    if (element.tagName === 'IMG') {
      // image styling is handled in the customBlockFn above
      return null
    }
    if (element.tagName === 'A') {
      let data = {}
      if (element.hasAttribute('target')) {
        data = {target: element.getAttribute('target'), rel: 'noreferrer'}
      }
      return Entity('LINK', {...data, url: element.getAttribute('href')})
    }
    let style = element.getAttribute('style')

    if (!style) {
      return null
    }

    // if the element has multiple styles applied pass them all together as-is because the html import library's
    // "Style" function currently doesn't support processing multiple styles separately
    if (style.includes(';')) {
      return Style(style)
    }
    // otherwise format the style to match the customStyleMap
    style = style.split(':')
    const key = camelCase(style.shift().trim())
    const val = style.join(':').trim()
    style = `${key}.${val}`
    if (style === 'textDecoration.underline') {
      return null
    } // underline is handled automatically, don't override it
    return Style(style)
  },
}

function getClassesAndStyles({
  block,
  blockStyles = OrderedSet(),
  classes = OrderedSet(),
}: {
  block?: ContentBlock
  blockStyles?: any
  classes?: any
}) {
  const data = block?.getData()
  data
    ?.filter((v, k) => !['depth', 'listStyles', 'listStart'].includes(k))
    .forEach((v, k) => {
      if (v === 'class') {
        classes = classes.add(k)
      } else {
        blockStyles = blockStyles.add(`${k}: ${v}`)
      }
    })
  const margin = block?.get('depth')
  if (margin) {
    blockStyles = OrderedSet.of([`margin-left: ${margin * 2.5}em`]).union(blockStyles)
  }
  // convert classes & styles to strings and return
  classes = (classes.size && ` class="${classes.toArray().join(' ')}"`) || ''
  blockStyles = (blockStyles.size && ` style="${blockStyles.toArray().join('; ')}"`) || ''
  return `${classes}${blockStyles}`
}

function buildHtmlForBlockText(
  result?: string,
  block?: ContentBlock | null,
  contentState?: ContentState
) {
  if (!block) {
    return '<span>&nbsp;</span>'
  }
  // now build the html for all inline styles for each "styleRange" in the block. A styleRange is
  // any sequence in the block where the characters share the same inline styling.
  block.findStyleRanges(
    () => true,
    (s, e) => {
      let close = ''
      let styles = block.getInlineStyleAt(s) as any

      styles = styles ? ` style="${styles}"` : ''
      // If a styleRange overlaps with an "entity" that starts and ends at the same points in the block
      // the entity represents an embeded link
      const startKey = block.getEntityAt(s)
      const endKey = block.getEntityAt(e - 1)
      const entity = startKey && startKey === endKey ? contentState?.getEntity(startKey) : null

      if (styles) {
        result += `<span${styles}>`
        close = '</span>' + close
      }
      // Now add the text content of the block for the current styleRange. If a "link" entity exists for this range
      // then wrap the text content in an anchor tag and add the href.
      // The multiple "replace" calls prevent empty paragraphs and extra spaces from collapsing and failing to render.
      const textContent = block
        .getText()
        .slice(s, e)
        .replace(/\n/g, '<br>')
        .replace(/\s{2,}?/g, '&nbsp;&nbsp;')
        .replace(/^\s$/g, '&nbsp;')
      if (entity && entity.getType() === 'LINK') {
        const {url, target} = entity.getData()
        result += `<a href="${url}" ${
          target ? `target="${target}" rel="noreferrer"` : ''
        }>${textContent}</a>`
      } else {
        result += textContent
      }
      result += close
    }
  )
  return result
}

export const getStateToHtmlOptions = (contentState: ContentState) => ({
  /**
   * NOTE: the rich text editor relies on the following styles for ordered lists. For ordered list numbering to display correctly
   * these styles should be included in the style tag or style sheet of any document that includes content from the rich text editor:
   * .ordered-list-item:before { left: -36px; position: absolute; text-align: right; width: 30px; }
   * .ordered-list-item:before { content: counter(ol0) ". "; counter-increment: ol0; }
   * .ordered-list-item.depth1:before { content: counter(ol1, lower-alpha) ") "; counter-increment: ol1; }
   * .ordered-list-item.depth2:before { content: counter(ol2, lower-roman) ". "; counter-increment: ol2; }
   * .ordered-list-item.depth3:before { content: counter(ol3, upper-alpha) ". "; counter-increment: ol3; }
   * .ordered-list-item.depth4:before { content: counter(ol4) ". "; counter-increment: ol4; }
   * .list.depth0:first-of-type { counter-reset: ol0; }
   * .list.depth1:first-of-type { counter-reset: ol1; }
   * .list.depth2:first-of-type { counter-reset: ol2; }
   * .list.depth3:first-of-type { counter-reset: ol3; }
   * .list.depth4:first-of-type { counter-reset: ol4; }
   **/

  // Converting (rendering) custom block types, like "paragraph" and "horizontal-rule" to html is handled here
  blockRenderers: {
    // each draft.js block of type "paragraph" is passed through this function for export as html
    paragraph: (block: ContentBlock) => {
      if (block.getLength() === 0) {
        return `<p${getClassesAndStyles({block})}><br></p>`
      }
      // get block-level styling and classes if any
      // "result" will be the html eventually returned from this function
      const result = `<p${getClassesAndStyles({
        block,
      })}>${buildHtmlForBlockText('', block, contentState)}</p>`
      return result
    },
    unstyled: (block: ContentBlock) => {
      if (block.getLength() === 0) {
        return `<div${getClassesAndStyles({block})}><br></div>`
      }
      // get block-level styling and classes if any
      // "result" will be the html eventually returned from this function
      const result = `<div${getClassesAndStyles({
        block,
      })}>${buildHtmlForBlockText('', block, contentState)}</div>`
      return result
    },
    'horizontal-rule': () => {
      return '<hr>'
    },
    atomic: (block: ContentBlock) => {
      const data = block.getData()
      let figStyle: any = []
      let imgStyle: any = []
      let classes: any = []
      data.forEach((v: any, k: string) => {
        if (v === 'class') {
          classes.push(k)
        } else if (k === 'imgStyle') {
          // styles on img tag are saved under the key imgStyle
          v.forEach((vv: string, kk: string) => imgStyle.push(`${kk}: ${vv}`))
        } else {
          figStyle.push(`${k}: ${v}`)
        }
      })
      const float = data.get('float')
      if (float && !data.get('margin')) {
        figStyle.push(float === 'right' ? 'margin: 0 8px 0 0' : 'margin: 0 0 0 8px')
      }
      if (block.get('depth')) {
        figStyle.push(`margin-left: ${block.get('depth') * 2.5}em; `)
      }
      classes = classes.join(' ') && ` class="${classes.join(' ')}"`
      figStyle = figStyle.join('; ') && ` style="${figStyle.join('; ')}"`
      imgStyle = ` style="${imgStyle.join('; ')}"`

      const {src} =
        (block.getEntityAt(0) && contentState.getEntity(block.getEntityAt(0)).getData()) || {}
      return `<figure${classes}${figStyle}><img src="${src}"${imgStyle}/></figure>`
    },
    'pasted-list-item': (block: ContentBlock) => {
      const prevBlock = contentState.getBlockBefore(block.getKey())
      if (prevBlock?.getType() === block.getType()) {
        return ''
      }
      const data = block.getData()
      let start = data.get('listStart')
      start = (start && ` start="${start}"`) || ''
      let listStyles = Map(data.get('listStyles'))
        .reduce((set: any, v, k) => {
          return set.add(`${k}: ${v}`)
        }, OrderedSet())
        .toArray()
        .join('; ')
      listStyles = listStyles && ` style="${listStyles}"`
      const listItems = contentState
        .getBlockMap()
        .skipUntil((v) => v === block)
        .takeWhile((v: any) => v.getType().endsWith('list-item'))
        .toList()
      const listTag = block.getData().get('listStart') > 0 ? 'ol' : 'ul'
      let currentDepth = block.getDepth()
      return `<${listTag}${listStyles}${start}>${listItems
        .map((block?: ContentBlock) => {
          const depth = block?.getDepth()
          const openTag =
            depth && depth > currentDepth
              ? `<${listTag}><li`
              : depth && depth < currentDepth
              ? `</${listTag}><li`
              : '<li'
          currentDepth = depth || 0
          return `
${openTag}${getClassesAndStyles({block})}>${buildHtmlForBlockText('', block, contentState)}</li>`
        })
        .toArray()
        .join('')}</${listTag}>`
    },
    table: (block: ContentBlock) => {
      const prevBlock = contentState.getBlockBefore(block.getKey())
      if (prevBlock && prevBlock.getType() === 'table') {
        return ''
      }
      const data = block.getData()
      const tableShape = data.get('tableShape')
      if (!tableShape) {
        return '<table><tbody><tr><td>&nbsp;</td></tr></tbody></table>'
      }
      let tableStyle = Map(data.get('tableStyle'))
        .reduce((set: any, v, k) => {
          return set?.add(`${k}: ${v}`)
        }, OrderedSet())
        .toArray()
        .join('; ')
      tableStyle = tableStyle && ` style="${tableStyle}"`
      const tableKey = data.get('tableKey')
      const tableBlocks = contentState
        .getBlockMap()
        .skipUntil(
          (v?: ContentBlock) =>
            v?.getType() === 'table' && v?.getData().get('tableKey') === tableKey
        )
        .takeWhile((v?: ContentBlock) => v?.getType() === 'table')
        .toList()
      const colgroup = data.get('tableColgroup') ?? ''
      let cellCounter = 0
      return `<table${tableStyle}>${colgroup}<tbody>${tableShape
        .map((row: any, i: number) => {
          let rowStyle = Map(block.getData().get('rowStyle')[i])
            .reduce((set: any, v, k) => {
              return set?.add(`${k}: ${v}`)
            }, OrderedSet())
            .toArray()
            .join('; ')
          rowStyle = rowStyle && ` style="${rowStyle}"`
          return `<tr${rowStyle}>${row
            .map((cell: any, j: number) => {
              const tag = cell.element
              let cellStyle = Map(cell.style)
                .reduce((set: any, v, k) => {
                  return set?.add(`${k}: ${v}`)
                }, OrderedSet())
                .toArray()
                .join('; ')
              cellStyle = cellStyle && ` style="${cellStyle}"`
              let cellBlock: ContentBlock | null = tableBlocks.get(cellCounter)
              let colspan = cellBlock.getData().get('colspan')
              colspan = colspan ? ` colspan=${colspan}` : ''
              let rowspan = cellBlock.getData().get('rowspan')
              rowspan = rowspan ? ` rowspan=${rowspan}` : ''

              const [, rowNum, colNum] = cellBlock?.getData().get('tablePosition').split('-') ?? []
              if (i !== +rowNum || j !== +colNum) {
                cellBlock = null
              } else {
                cellCounter++
              }
              return `<${tag}${cellStyle}${colspan}${rowspan}>${buildHtmlForBlockText(
                '',
                cellBlock,
                contentState
              )}</${tag}>`
            })
            .join('')}</tr>`
        })
        .join('')}</tbody></table>`
    },
  },

  defaultBlockTag: 'div',
})

export const handleKeypressWhenSelectionNotCollapsed = (
  newEditorState: EditorState,
  chars: string = '',
  onChange: (state: EditorState) => void
) => {
  let selection = newEditorState.getSelection()
  let content = newEditorState.getCurrentContent()
  const startKey = selection.getStartKey()
  const startBlock = content.getBlockForKey(startKey)
  const endKey = selection.getEndKey()
  const endBlock = content.getBlockForKey(endKey)
  const prevBlock = content.getBlockBefore(startKey)
  const nextBlock = content.getBlockAfter(endKey)
  const firstCell =
    startBlock.getType() === 'table' &&
    selection.getStartOffset() === 0 &&
    prevBlock?.getType() !== 'table'
  const firstTableKey = startBlock.getData().get('tableKey')
  const lastCell =
    endBlock.getType() === 'table' &&
    selection.getEndOffset() === endBlock.getLength() &&
    nextBlock?.getType() !== 'table'
  const lastTableKey = endBlock.getData().get('tableKey')
  const sameTable = firstTableKey === lastTableKey

  if (
    startBlock === endBlock ||
    (startBlock.getType() !== 'table' && endBlock.getType() !== 'table')
  ) {
    return 'not-handled'
  }

  let blockMap = content.getBlockMap()
  const startOffset = selection.getStartOffset()
  const endOffset = selection.getEndOffset()
  let blocks = blockMap
    .toSeq()
    .skipUntil((v) => v === startBlock)
    .takeUntil((v) => v === nextBlock) // take up to but not including nextBlock
    .map((block: any) => {
      let text
      const type = block.getType()
      if (block === startBlock) {
        text = block.getText().slice(0, startOffset) + chars
        return block.set('text', type === 'table' ? text || ' ' : text)
      }
      if (block === endBlock) {
        text = block.getText().slice(endOffset, block.getLength())
        return block.set('text', type === 'table' ? text || ' ' : text)
      }
      return block.set('text', type === 'table' ? ' ' : '')
    })
    .toOrderedMap()

  switch (true) {
    case startBlock.getType() !== 'table' && lastCell: // remove all selected blocks
    case firstCell && lastCell: // remove all selected blocks
      blockMap = blockMap.merge(blocks)
      blockMap = blockMap.filter(
        (block: any, key: any) =>
          !blocks.has(key) || (block.getType() !== 'table' && block.getText())
      ) as any
      if (!blockMap.size) {
        const key = genKey()
        blockMap = blockMap.merge(
          Map([[key, new ContentBlock({key, type: 'unstyled', text: '', data: Map()})]])
        )
      }
      break
    case firstCell && endBlock.getType() !== 'table': {
      // remove all selected blocks, but preserve inline style/entities in partial block after selection
      content = Modifier.removeRange(content, selection, 'backward')
      blockMap = content.getBlockMap()
      const firstBlock = blockMap.first().set('type', 'unstyled').set('data', Map()) as any
      blockMap = blockMap.merge(Map([[firstBlock.getKey(), firstBlock]]))
      break
    }
    case sameTable: {
      // clear cell contents in part of a table
      blocks = blocks.butLast() as any
      blockMap = blockMap.merge(blocks)
      let subSelection = SelectionState.createEmpty(endKey)
      subSelection = subSelection.merge({
        focusOffset: selection.getEndOffset(),
      })
      content = content.set('blockMap', blockMap) as any
      content = Modifier.removeRange(content, subSelection, 'backward')
      blockMap = content.getBlockMap()
      break
    }
    case firstCell: {
      // remove selected blocks, but just clear contents of blocks matching lastTableKey
      const notLastTable = blocks.filter(
        (block) => block.getData().get('tableKey') !== lastTableKey
      )
      blockMap = blockMap.merge(blocks.butLast())
      blockMap = blockMap.filter((_, key: any) => !notLastTable.has(key)) as BlockMap
      let subSelection = SelectionState.createEmpty(endKey)
      subSelection = subSelection.set('focusOffset', selection.getEndOffset()) as SelectionState
      content = content.set('blockMap', blockMap) as any
      content = Modifier.removeRange(content, subSelection, 'backward')
      blockMap = content.getBlockMap()
      break
    }
    case lastCell: {
      // clear contents of blocks matching firstTableKey & remove all other selected blocks
      const notFirstTable = blocks.filter(
        (block) => block.getData().get('tableKey') !== firstTableKey
      )
      blockMap = blockMap.merge(blocks)
      blockMap = blockMap.filter((_, key: any) => !notFirstTable.has(key)) as BlockMap
      break
    }
    case startBlock.getType() === 'table' && endBlock.getType() === 'table' && !sameTable: {
      // clear contents of firstTableKey & lastTableKey, & delete all blocks in between, but leave one empty block to separate the tables.
      const notTable = blocks.filter((block) => !block.getData().get('tableKey'))
      const separatorBlock = notTable.first()
      blockMap = blockMap.merge(blocks.butLast())
      blockMap = blockMap.filter(
        (_, key: any) => !notTable.has(key) || key === separatorBlock.getKey()
      ) as BlockMap
      blockMap = blockMap.merge(Map([[separatorBlock.getKey(), separatorBlock.set('text', ' ')]]))
      let subSelection = SelectionState.createEmpty(endKey)
      subSelection = subSelection.set('focusOffset', selection.getEndOffset()) as SelectionState
      content = content.set('blockMap', blockMap) as any
      content = Modifier.removeRange(content, subSelection, 'backward')
      blockMap = content.getBlockMap()
      break
    }
    case startBlock.getType() !== 'table' && endBlock.getType() === 'table': {
      //
      if (prevBlock?.getType() === 'table') {
        const separatorBlock = blocks.first().set('text', ' ')
        blocks = blocks.merge([[separatorBlock.getKey(), separatorBlock]])
      }
      blockMap = blockMap.merge(blocks.butLast())
      blockMap = blockMap.filter((block: any) => block.getLength()) as BlockMap
      let subSelection = SelectionState.createEmpty(endKey)
      subSelection = subSelection.set('focusOffset', selection.getEndOffset()) as SelectionState
      content = content.set('blockMap', blockMap) as any
      content = Modifier.removeRange(content, subSelection, 'backward')
      blockMap = content.getBlockMap()
      break
    }
    default: {
      // clear contents of firstTableKey, delete other blocks
      const notTableStart = blocks.find((block) => !block.getData().get('tableKey')).getKey()
      const table = blocks.filter((block) => block.getData().get('tableKey') === firstTableKey)
      blockMap = blockMap.merge(table)
      content = content.set('blockMap', blockMap) as any
      let subSelection = SelectionState.createEmpty(notTableStart)
      subSelection = subSelection.merge({
        focusKey: endKey,
        focusOffset: endOffset,
      })
      content = Modifier.removeRange(content, subSelection, 'backward')
      blockMap = content.getBlockMap()
    }
  }
  // create a new collapsed selection positioned where the former selection started
  const selectionKey = blockMap.has(startKey)
    ? startKey
    : blockMap.has(endKey)
    ? endKey
    : prevBlock?.getKey() || nextBlock?.getKey() || blockMap.first().getKey()
  selection = SelectionState.createEmpty(selectionKey)
  selection = selection.merge({
    anchorKey: selectionKey,
    anchorOffset: startOffset + chars.length,
    focusKey: selectionKey,
    focusOffset: startOffset + chars.length,
  })
  content = content.set('blockMap', blockMap) as any
  newEditorState = EditorState.push(newEditorState, content, 'remove-range')
  newEditorState = EditorState.forceSelection(newEditorState, selection)
  onChange(newEditorState)
  return 'handled'
}
