import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import _ from 'lodash'
import {Button, ColorVariant} from '../../../inputs/Button'
import {MetronicIconButton} from '../../../inputs/MetronicIconButton'
import {FilterSearchableSelectInput} from '../../../inputs/SearchableSelect/FilterSearchableSelectInput'
import {SelectInputItem} from '../../../inputs/SelectInput'
import {SweetAlert} from '../../../modals/SweetAlert'
import {LoadingSpinner} from '../../../utils/LoadingSpinner'
import {ProductModel} from '../../../../models/ems/ProductModel'
import {FilterModel} from '../../../../models/FilterModel'
import {GlobalSearchModel} from '../../../../models/GlobalSearchModel'
import {ProductVoucherModalType} from '../../../../models/booking-wizard/BookingWizard'
import {
  ProductVoucherBulkModel,
  BulkConsolidatedFormValues,
} from '../../../../models/booking-wizard/BulkConsolidatedBookingWizard'
import {VoucherModel} from '../../../../models/svc/VoucherModel'
import clsx from 'clsx'
import {useAlerts} from '../../../alerts/useAlerts'
import {Badge} from '../../../badge/Badge'
import {useSafeStateUpdate} from '../../../hooks/useSafeStateUpdate'

interface AddBulkProductVoucherProps {
  modalType: ProductVoucherModalType
  onModalClose: () => void
  onAdd: (type: ProductVoucherModalType, value: ProductVoucherBulkModel) => void
  productSearchResults?: GlobalSearchModel<ProductModel> | null
  refreshProductsList: (filter?: FilterModel) => void
  productsValues: ProductVoucherBulkModel | null
  voucherSearchResults?: GlobalSearchModel<VoucherModel>
  vouchersValues: ProductVoucherBulkModel | null
  searchVouchers: (filter?: FilterModel) => void
  isPortal?: boolean
  bookingBulkForm?: BulkConsolidatedFormValues
  selectedItems?: ProductVoucherBulkModel[]
}

export const AddBulkProductVoucher = ({
  modalType,
  onModalClose,
  onAdd,
  productSearchResults,
  refreshProductsList,
  productsValues,
  searchVouchers,
  vouchersValues,
  voucherSearchResults,
  isPortal,
  bookingBulkForm,
  selectedItems,
}: AddBulkProductVoucherProps) => {
  const [value, setValue] = useState<ProductVoucherBulkModel | null>(
    modalType === 'product' ? productsValues : vouchersValues
  )
  const [product, setProduct] = useState<ProductModel | null>(null)
  const [voucher, setVoucher] = useState<VoucherModel | null>(null)
  const {pushError} = useAlerts()
  const safeUpdate = useSafeStateUpdate()

  const filteredSearchResult = useMemo(() => {
    if (productSearchResults) {
      return {
        ...productSearchResults,
        data: productSearchResults.data.filter(
          (item) =>
            !selectedItems?.some(
              (selectedItem) => selectedItem && itemMapper(item).value === selectedItem.code
            )
        ),
      }
    }
  }, [productSearchResults, selectedItems])

  useEffect(() => {
    if (!modalType) {
      setProduct(null)
      setVoucher(null)
    }
  }, [modalType])

  const isProduct = useMemo(() => {
    return modalType === 'product'
  }, [modalType])

  const handleProductQTYComputation = useCallback(
    async (newValue: ProductVoucherBulkModel) => {
      if (newValue?.maxQTY && bookingBulkForm?.customers) {
        const diff = newValue.maxQTY - newValue?.qty
        if (diff >= 0) {
          newValue.remainingQty = newValue.maxQTY
          if (newValue.product?.children?.length) {
            if (selectedItems?.length) {
              let childArray: any[] = []
              for (const selected of selectedItems) {
                const found = newValue.product.children.find((item) => {
                  if (item.code === selected.code) {
                    return item
                  }
                })

                if (found) {
                  childArray.push(selected)
                }
              }
              const highestQty = _.orderBy(childArray, ['qty'], ['desc'])

              if (highestQty.length) {
                const initialDiff = newValue.remainingQty - highestQty[0].qty
                newValue.remainingQty = initialDiff
              }

              const parentRemain = newValue.remainingQty - newValue.qty
              if (parentRemain >= 0) {
                newValue.remainingQty = parentRemain
                safeUpdate(() => setValue(newValue))
              } else {
                newValue.remainingQty = 0
              }
            } else {
              if (newValue.remainingQty) {
                newValue.remainingQty = newValue.remainingQty - newValue.qty
                safeUpdate(() => setValue(newValue))
              }
            }
          } else {
            //child product
            if (selectedItems?.length) {
              for (const selected of selectedItems) {
                if (selected.product?.children?.length) {
                  const found = selected.product.children.find((item) => {
                    if (item.code === newValue.code) {
                      return item
                    }
                  })
                  if (found) {
                    newValue.remainingQty = newValue.remainingQty - selected.qty
                  }
                }
              }
              newValue.remainingQty = newValue.remainingQty - newValue.qty
              if (newValue.remainingQty < 0) {
                pushError(new Error(`Cannot add this product reached maximum quantity.`))
                newValue.remainingQty = 0
              } else {
                safeUpdate(() => setValue(newValue))
              }
            } else {
              const parentRemain = newValue.remainingQty - newValue.qty
              if (parentRemain >= 0) {
                newValue.remainingQty = newValue.remainingQty - newValue.qty
              } else {
                newValue.remainingQty = 0
              }
              safeUpdate(() => setValue(newValue))
            }
          }
        } else {
          pushError(new Error(`Cannot add this product reached maximum quantity.`))
        }
      } else {
        pushError(new Error(`Cannot add this product reached maximum quantity.`))
      }
    },
    [pushError, bookingBulkForm?.customers, selectedItems]
  )

  const handleCountChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      let count = e.target.value
      if (Number(count) === 0) {
        count = '1'
      }
      if (value) {
        const newValue: ProductVoucherBulkModel = {...value, qty: Number(count)}
        if (isPortal) {
          await handleProductQTYComputation(newValue)
        } else {
          setValue(newValue)
        }
      }
    },
    [value, isPortal, handleProductQTYComputation]
  )

  const handleProductChange = useCallback(
    async (value) => {
      if (isProduct) {
        setProduct(value)
      }

      if (!isProduct) {
        setVoucher(value)
      }
      const newValue: ProductVoucherBulkModel = {
        code: value.code,
        name: value.name,
        qty: 1,
        isSeated: value?.isSeated ? value?.isSeated : false,
        type: isProduct ? (value.type === 'bundle' ? 'bundle' : 'product') : 'voucher',
        remainingQty: value.remainingQty,
        startedAt: value.startedAt,
        endedAt: value.endedAt,
        isTimeslot: value.isTimeslot,
        timeslots: [],
        product: value,
        maxQTY: value.remainingQty,
      }
      if (isPortal) {
        await handleProductQTYComputation(newValue)
      } else {
        setValue(newValue)
      }
    },
    [isProduct, isPortal, handleProductQTYComputation]
  )

  const handleAdd = useCallback(() => {
    if (isProduct && value) onAdd(modalType, value)
    if (!isProduct && value) onAdd(modalType, value)
  }, [isProduct, modalType, onAdd, value])

  const updatedData = useMemo(() => {
    if (value && product) {
      return {...value, qty: value?.qty || 0}
    } else {
      return {qty: 0}
    }
  }, [value, product])

  const customerCount = useMemo(() => {
    // if (value?.qty && bookingBulkForm?.customers && value?.remainingQty) {
    //   return value.remainingQty - value?.qty
    // }
    if (value?.remainingQty) {
      return value.remainingQty
    }
  }, [bookingBulkForm, value])

  const renderItems = useCallback(
    (item) => {
      const label = item ? itemMapper(item).label : 'No Name'
      const value = item ? itemMapper(item).value : 'No Name'
      let type: any = null
      let badge: ColorVariant = 'warning'
      switch (value.substring(0, 3)) {
        case 'PRD':
          type = 'product'
          badge = 'info'
          break
        case 'VCH':
          type = 'voucher'
          badge = 'primary'
          break
        default:
          type = 'bundle'
      }
      return (
        <div
          className='align-items-center mb-1'
          role='button'
          onMouseDown={() => handleProductChange(item)}
        >
          <div className='d-flex justify-content-between'>
            {label}
            {type !== 'voucher' ? (
              <Badge uppercase variant={`${badge}`}>
                {type}
              </Badge>
            ) : null}
          </div>
          {type !== 'voucher' ? (
            <div className='w-100 searchable-input-item-botom-border my-2' />
          ) : null}
        </div>
      )
    },
    [handleProductChange]
  )

  return (
    <SweetAlert
      showConfirmButton={false}
      open={modalType !== undefined}
      onClose={onModalClose}
      containerClassName='overflow-auto'
      customClass={{htmlContainer: 'overflow-visible'}}
    >
      {/* {isPortal && <div className='swal2-corners'></div>} */}
      {isPortal && (
        <div className='swal2-corners' style={{width: '450px'}}>
          <div className='d-flex justify-content-end mt-5 me-5'>
            <MetronicIconButton
              variant='text'
              size='md'
              iconType='Navigation'
              iconName='Close'
              tooltip='Close Modal'
              onClick={onModalClose}
            />
          </div>
        </div>
      )}
      <div
        className={clsx('h-100 d-flex flex-column', isPortal && 'position-relative')}
        style={{width: isPortal ? '350px' : ''}}
      >
        <div className='d-flex flex-column flex-grow-1 text-start'>
          {!isPortal && (
            <div className='position-absolute top-0 end-0'>
              <MetronicIconButton
                variant='text'
                size='md'
                iconType='Navigation'
                iconName='Close'
                tooltip='Close Modal'
                onClick={onModalClose}
              />
            </div>
          )}
          <h2 className='text-start mb-5'>Select product</h2>
          <div className='mb-5'>
            <FilterSearchableSelectInput
              value={isProduct ? product : voucher}
              itemMapper={itemMapper}
              searchResult={isProduct ? filteredSearchResult : voucherSearchResults}
              placeholder='Select product'
              onChange={handleProductChange}
              onFilter={isProduct ? refreshProductsList : searchVouchers}
              noMargin
              renderItem={renderItems}
            />
          </div>
          <div className=''>
            <div>
              <label className='text-start text-gray-900 mb-5'>Quantity</label>
            </div>
            <div className='d-flex justify-content-between'>
              <div>
                <input
                  onChange={handleCountChange}
                  className='product-input-item-input-container__number-input form-control form-control-solid'
                  type='number'
                  value={updatedData.qty}
                  min={0}
                  max={value?.maxQTY || undefined}
                />
              </div>
              {product && value && value.remainingQty && customerCount && value.remainingQty > 0 ? (
                <div className='flex-grow-1'>
                  <div className='d-flex flex-column justify-content-center align-items-end fs-7 h-100'>
                    {`Remaining Quantity: ${
                      // value.remainingQty - (value?.qty * customerCount || 0)
                      customerCount
                    }`}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='d-flex gap-5 justify-content-center mt-5'>
          {isPortal ? (
            <Button
              className='btn btn-primary align-items-center position-relative btn-cut-conrner pe-20 w-100'
              onClick={handleAdd}
            >
              <LoadingSpinner loading={false}>Save</LoadingSpinner>
            </Button>
          ) : (
            <>
              <Button
                variant='primary'
                disabled={product || voucher || updatedData.qty > 0 ? false : true}
                onClick={handleAdd}
              >
                <LoadingSpinner loading={false}>Save</LoadingSpinner>
              </Button>
              <Button className='me-1' onClick={onModalClose}>
                Close
              </Button>
            </>
          )}
        </div>
      </div>
    </SweetAlert>
  )
}

const itemMapper = (data: ProductModel | VoucherModel): SelectInputItem => {
  return {
    label: data.name || '',
    value: data.code || '',
  }
}
