import clsx from 'clsx'
import {useMemo} from 'react'
import analyticsImage from './Analytics.png'
import fAndBImage from './f-and-b.svg'
import gridImage from './Grid.png'
import locationImage from './Location.png'
import oasisImage from './Oasis.png'

export interface DashboardTabsProps {
  value: DashboardTabValue
  onChange?: (value: DashboardTabValue) => void
  className?: string
  isHasFandBAccess?: boolean
  isHasDashboardAccess?: boolean
}

export const DasboardTabs = ({
  value,
  onChange,
  className,
  isHasFandBAccess,
  isHasDashboardAccess,
}: DashboardTabsProps) => {
  const tabs = useMemo(() => {
    const allTabs: any[] = []

    if (isHasDashboardAccess) {
      allTabs.push(
        {
          image: gridImage,
          label: 'Grid',
          onClick: () => onChange?.('grid'),
          isActive: value === 'grid',
        },
        {
          image: locationImage,
          label: 'Location',
          onClick: () => onChange?.('map'),
          isActive: value === 'map',
        },
        {
          image: oasisImage,
          label: 'Oasis',
          onClick: () => onChange?.('oasis'),
          isActive: value === 'oasis',
        },
        {
          image: analyticsImage,
          label: 'Analytics',
          onClick: () => onChange?.('analytics'),
          isActive: value === 'analytics',
        }
      )
    }

    if (isHasFandBAccess) {
      allTabs.push({
        image: fAndBImage,
        label: 'F&B',
        onClick: () => onChange?.('F&B'),
        isActive: value === 'F&B',
      })
    }

    return allTabs
  }, [isHasDashboardAccess, isHasFandBAccess, onChange, value])

  return (
    <div className={clsx(className, 'd-flex gap-5 flex-wrap justify-content-center')}>
      {tabs.map((tab) => {
        return (
          <div key={tab.label} className='w-200px'>
            <button
              onClick={tab.onClick}
              className={clsx(
                'btn w-100 h-100 py-6 px-lg-20 d-inline-flex align-items-center justify-content-center border border-primary',
                tab.isActive ? 'bg-primary' : undefined
              )}
            >
              <img style={{height: '30px'}} src={tab.image} alt={tab.label}></img>
              <span className='fs-2 d-none d-md-block fw-bolder text-white ps-5'>{tab.label}</span>
            </button>
          </div>
        )
      })}
    </div>
  )
}

export type DashboardTabValue = 'grid' | 'map' | 'oasis' | 'analytics' | 'F&B'
