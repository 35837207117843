import * as yup from 'yup'
import 'moment-timezone'
import moment from 'moment'
import {
  BookingModelCreateParamsBundles,
  BulkBookingModelCreateParams,
  NonSeatedProductTimeSlot,
} from '../../../models/ems/BookingModel'
import {TicketModelFulfillBulkParams} from '../../../models/ems/TicketModel'
import {DateUtil} from '../../../utils/DateUtil'
import {EventModel} from '../../../models/ems/EventModel'
import {SeatMapValueObject} from '../../inputs/SeatMapInput/SeatMapValue'
import {BulkConsolidatedFormValues} from '../../../models/booking-wizard/BulkConsolidatedBookingWizard'

export const bulkConsolidatedBookingValidationSchema = yup.object().shape({
  eventCode: yup.string().required(),
  customers: yup
    .array()
    .test('has-atleast-one-customer', 'Please add at least one customer', (value) => {
      if (!value || value.length === 0) {
        return false
      }

      return true
    }),
  products: yup
    .array()
    .test('has-atleast-one-product', 'Please add at least one product', (value, context) => {
      const formValues = context.parent as BulkConsolidatedFormValues

      if (
        formValues.products &&
        formValues.products.length > 0 &&
        formValues.products.some((item) => item.code && item.qty > 0)
      ) {
        return true
      }
      if (!value) {
        return false
      }

      return value.some((item) => {
        return Boolean(item.data) && item.count > 0
      })
    }),
  customersSeats: yup
    .array()
    .test('has-atleast-one-seat', 'Please add at least one Seat', (value, context) => {
      const formValues = context.parent as BulkConsolidatedFormValues
      const noOfCustomer = formValues.customers?.length
      let productCount = 0
      if (formValues.products?.length) {
        for (const prod of formValues.products) {
          if (prod.bundleProducts?.length) {
            prod.bundleProducts.forEach((b) => {
              if (b.isSeated) {
                productCount++
              }
            })
          }
          if (prod?.isSeated) {
            productCount++
          }
        }
        productCount = productCount * Number(noOfCustomer)
        if (productCount && productCount !== value?.length) {
          return false
        }
        return true
      } else {
        return false
      }
    }),
})

export const EMPTY_FORM_VALUES: BulkConsolidatedFormValues = {
  customers: [],
  eventCode: '',
  product: null,
  voucher: null,
  customersSeats: null,
  bundle: null,
  products: [],
  vouchers: [],
  bookingResults: [],
}

export const getBulkPayload = (
  values: BulkConsolidatedFormValues,
  event?: EventModel | null
): BulkBookingModelCreateParams[] => {
  if (!values.customers || !event) {
    throw new Error('Invalid form data.')
  }
  if (
    values.product?.isTimeslot &&
    !values.product?.isSeated &&
    !values.product.timeslots?.length
  ) {
    throw new Error('Please add Start & End dates.')
  }
  if (!values.products) {
    throw new Error('No products added.')
  }
  const payload: BulkBookingModelCreateParams[] = []
  const customerCount = values.customers.length
  let nonSeatedProdCount = 0
  let nonSeatedTimeslotCount = 0
  for (const p of values.products) {
    if (p.type === 'product' && !p.isSeated && p.isTimeslot) {
      nonSeatedProdCount++
    } else if (p.type === 'bundle') {
      p.bundleProducts?.forEach((b) => {
        if (b.isTimeslot && !b.isSeated) {
          nonSeatedProdCount++
        }
      })
    }
    if (p.timeslots?.length) {
      nonSeatedTimeslotCount += p.timeslots?.length
    }
  }
  if (nonSeatedProdCount * customerCount !== nonSeatedTimeslotCount) {
    throw new Error('Please complete timeslots for non-seated.')
  }

  for (let customer of values.customers) {
    let found: TicketModelFulfillBulkParams | null | undefined = null
    let products: ProductVoucherBulkModel[] = []
    let vouchers: ProductVoucherBulkModel[] = []
    let bundles: BookingModelCreateParamsBundles[] = []

    if (values.products) {
      for (const prod of values.products) {
        if (prod.bundleProducts?.length) {
          //BUNDLE PRODUCTS
          bundles.push({
            code: prod.code,
            qty: prod.qty,
            products: [],
          })
          for (const bundleProduct of prod.bundleProducts) {
            let bundleActualQty = 0
            if (bundleProduct.isSeated && values.customersSeats) {
              //seated products insde bundle
              found = values.customersSeats.find(
                (item) =>
                  item.customerCode === bundleProduct.code &&
                  item.productCode === bundleProduct.code &&
                  item.bundleCode === prod.code
              )
              if (bundleProduct.isTimeslot) {
                //seated timesloted products
                if (found) {
                  const startDateSeated = found?.startedAt
                    ? DateUtil.convertDateTimeToVenueTimezoneToUTC(
                        moment(moment(found?.startedAt).toDate()).format('YYYY-MM-DD HH:mm:ss'),
                        `Asia/Muscat`
                      )
                    : found?.startedAt
                  const endDateSeated = found?.endedAt
                    ? DateUtil.convertDateTimeToVenueTimezoneToUTC(
                        moment(moment(found?.endedAt).toDate()).format('YYYY-MM-DD HH:mm:ss'),
                        `Asia/Muscat`
                      )
                    : found?.endedAt
                  bundles[0].products?.push({
                    code: bundleProduct.code,
                    qty: prod.qty,
                    locationCode: found.locationCode,
                    seats: found.seats.getSeatMapObject(),
                    endedAt: endDateSeated || found?.endedAt,
                    startedAt: startDateSeated || found?.startedAt,
                  })
                }
              } else {
                //seated non timeslot produts
                if (found) {
                  bundles[0].products?.push({
                    code: bundleProduct.code,
                    qty: prod.qty,
                    locationCode: found.locationCode,
                    seats: found.seats.getSeatMapObject(),
                    endedAt: found?.endedAt,
                    startedAt: found?.startedAt,
                  })
                }
              }
            } else {
              //non seated products insde bundle
              if (bundleProduct.isTimeslot) {
                const found = prod.timeslots?.find(
                  (t) =>
                    t.customerCode === bundleProduct.code && t.productCode === bundleProduct.code
                )
                let timeslotsArray: any[] = []
                if (found && found.timeslot.length) {
                  const _startDate = moment(moment(found.timeslot[0].startedAt).toDate()).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                  const _endDate = moment(moment(found.timeslot[0].endedAt).toDate()).format(
                    'YYYY-MM-DD HH:mm:ss'
                  )
                  const startedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                    _startDate,
                    `Asia/Muscat`
                  )
                  const endedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                    _endDate,
                    `Asia/Muscat`
                  )
                  if (bundleProduct.bundleQty) {
                    bundleActualQty = bundleProduct.bundleQty * prod.qty
                  } else {
                    bundleActualQty = prod.qty
                  }
                  for (let i = 0; i < bundleActualQty; i++) {
                    timeslotsArray.push({
                      startedAt,
                      endedAt,
                    })
                  }
                  bundles[0].products?.push({
                    code: bundleProduct.code,
                    qty: prod.qty,
                    timeslots: timeslotsArray,
                  })
                }
              } else {
                bundles[0].products?.push({
                  code: bundleProduct.code,
                  qty: prod.qty,
                })
              }
            }
          }
        } else {
          //PRODUCTS
          if (prod.isSeated && values.customersSeats) {
            found = values.customersSeats.find(
              (item) =>
                item.customerCode === prod.code &&
                item.productCode === prod.code &&
                item.bundleCode === undefined
            )
            if (prod.isTimeslot) {
              if (found) {
                const startDateSeated = found?.startedAt
                  ? DateUtil.convertDateTimeToVenueTimezoneToUTC(
                      moment(moment(found?.startedAt).toDate()).format('YYYY-MM-DD HH:mm:ss'),
                      `Asia/Muscat`
                    )
                  : found?.startedAt
                const endDateSeated = found?.endedAt
                  ? DateUtil.convertDateTimeToVenueTimezoneToUTC(
                      moment(moment(found?.endedAt).toDate()).format('YYYY-MM-DD HH:mm:ss'),
                      `Asia/Muscat`
                    )
                  : found?.endedAt
                products.push({
                  code: prod.code,
                  qty: prod.qty,
                  locationCode: found.locationCode,
                  seats: found.seats.getSeatMapObject(),
                  endedAt: endDateSeated || found?.endedAt,
                  startedAt: startDateSeated || found?.startedAt,
                })
              }
            } else {
              if (found) {
                products.push({
                  code: prod.code,
                  qty: prod.qty,
                  locationCode: found.locationCode,
                  seats: found.seats.getSeatMapObject(),
                  endedAt: prod.startedAt,
                  startedAt: prod.endedAt,
                })
              }
            }
          } else {
            if (prod.isTimeslot) {
              const found = prod.timeslots?.find((t) => t.customerCode === prod.code)
              let timeslotsArray: any[] = []
              if (found && found.timeslot.length) {
                const _startDate = moment(moment(found.timeslot[0].startedAt).toDate()).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
                const _endDate = moment(moment(found.timeslot[0].endedAt).toDate()).format(
                  'YYYY-MM-DD HH:mm:ss'
                )
                const startedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                  _startDate,
                  `Asia/Muscat`
                )
                const endedAt = DateUtil.convertDateTimeToVenueTimezoneToUTC(
                  _endDate,
                  `Asia/Muscat`
                )
                for (let i = 0; i < prod.qty; i++) {
                  timeslotsArray.push({
                    startedAt,
                    endedAt,
                  })
                }
                products.push({
                  code: prod.code,
                  qty: prod.qty,
                  timeslots: timeslotsArray,
                })
              }
            } else {
              products.push({
                code: prod.code,
                qty: prod.qty,
              })
            }
          }
        }
      }
    }

    if (values.vouchers) {
      for (const voucher of values.vouchers) {
        vouchers.push({
          code: voucher.code,
          qty: voucher.qty,
        })
      }
    }

    payload.push({
      eventCode: values.eventCode || '',
      customerCode: customer.code,
      products: products,
      vouchers: vouchers,
      bundles: bundles,
    })
  }

  return payload
}

interface ProductVoucherBulkModel {
  code: string
  qty: number
  locationCode?: string
  seats?: SeatMapValueObject
  startedAt?: string
  endedAt?: string
  timeslots?: NonSeatedProductTimeSlot[]
}
