import {ComponentType, Fragment, ReactNode, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import {TableColumnOptions} from './TableColumn'
import {TableRow, TableRowId} from './TableRow'
import {TableSelectioncheckbox} from './TableSelectionCheckbox'
import {TableHeader} from './TableHeader'
import {SortValue} from './SortValue'
import {TableHiddenColumnDrawerMenu} from './TableHiddenColumnDrawerMenu'
import {useDrawerRef} from '../Drawer/useDrawerRef'
import {TableActionHeader} from './TableActionHeader'
import {MetronicIconButton} from '../inputs/MetronicIconButton'

export interface GroupedTableData<T> {
  field: string
  label: string
  key: TableRowId
  data: T[]
}
// kjnlvbzkjvnb;anvbcljkzv
export interface TableProps<T> {
  rightToolbar?: ReactNode
  leftToolbar?: ReactNode
  className?: string
  columns: TableColumnOptions<T>[]
  data?: T[]
  groupedData?: GroupedTableData<T>[]
  expandedGroups?: TableRowId[]
  grouping?: string
  onExpandedGroupsChange?: (data: TableRowId[]) => void
  actions?: (data: T) => ReactNode
  idExtractor: (row: T, index: number) => TableRowId
  onSelectionChange?: (data: TableRowId[]) => void
  onRowSelectionChange?: (data: TableRowId[]) => void
  selection?: TableRowId[]
  expandedRows?: TableRowId[]
  onExpandChange?: (data: TableRowId[]) => void
  expansion?: ComponentType<{data: T}>
  sortedColumn?: SortValue
  onSort?: (sortValue: SortValue) => void
  title?: string
  hiddenColumns?: string[]
  onHiddenColumnsChange?: (hiddenColumns: string[]) => void
  body?: ReactNode
  hideSelectAll?: boolean
  isSelectDisabled?: (data: T) => boolean
  isExpansionHidden?: (data: T) => boolean
  groupRowActions?: (data: GroupedTableData<T>) => ReactNode
  classes?: {
    root?: string
    table?: string
    header?: string
    row?: ((data: T) => string | undefined) | string
  }
  isSelectSingleRow?: boolean
  isAllCheckBoxDisabled?: boolean
  noPadding?: boolean
  isFromFilterTable?: boolean
}

export const Table = <T,>({
  leftToolbar,
  rightToolbar,
  data,
  groupedData,
  expandedGroups,
  onExpandedGroupsChange,
  actions,
  columns,
  onSelectionChange,
  onRowSelectionChange,
  idExtractor,
  selection,
  className,
  sortedColumn,
  onSort,
  title,
  hiddenColumns,
  onHiddenColumnsChange,
  body,
  expansion,
  expandedRows,
  onExpandChange,
  hideSelectAll,
  isSelectDisabled,
  isExpansionHidden,
  groupRowActions,
  grouping,
  classes,
  isSelectSingleRow,
  isAllCheckBoxDisabled,
  noPadding = false,
  isFromFilterTable = false,
}: TableProps<T>) => {
  const [hideColumnDrawer, setHideColumnDrawer] = useDrawerRef()

  const headerColumns = useMemo(() => {
    if (grouping) {
      const rearrangedColumns = [...columns]
      const columnIndex = rearrangedColumns.findIndex((c) => c.field === grouping)
      if (columnIndex >= 0) {
        rearrangedColumns.splice(columnIndex, 1)
      }
      return rearrangedColumns
    }
    return columns
  }, [columns, grouping])

  const dataLength = useMemo(() => {
    if (data) {
      return data.length
    }
    if (groupedData) {
      return Object.values(groupedData).reduce((acc, group) => {
        acc += group.data.length
        return acc
      }, 0)
    }
  }, [data, groupedData])

  const selectionAllState = useMemo(() => {
    if (selection && selection.length > 0) {
      if (dataLength !== undefined && selection.length === dataLength) {
        return true
      }
      return null
    }
    if (selection === undefined) {
      return undefined
    }
    return false
  }, [selection, dataLength])

  const isColumnAscending = useCallback(
    (column: string) => {
      if (sortedColumn) {
        if (sortedColumn[0] === column) {
          return sortedColumn[1]
        }
      }
    },
    [sortedColumn]
  )

  const isColumnHidden = useCallback(
    (column: string) => {
      if (hiddenColumns) {
        return hiddenColumns.includes(column)
      }
      return false
    },
    [hiddenColumns]
  )

  const onSelectHandler = useCallback(
    (id: TableRowId) => {
      if (selection && onSelectionChange) {
        const index = selection.indexOf(id)
        const newValue = [...selection]
        if (index >= 0) {
          newValue.splice(index, 1)
          onSelectionChange(newValue)
        } else {
          newValue.push(id)
          onSelectionChange(newValue)
        }
      }
    },
    [selection, onSelectionChange]
  )

  const handleonRowSelect = useCallback(
    (id: TableRowId) => {
      if (selection && onRowSelectionChange) {
        if (isSelectSingleRow) {
          const newValue: TableRowId[] = []
          newValue.push(id)
          onRowSelectionChange(newValue)
        } else {
          const index = selection.indexOf(id)
          const newValue = [...selection]
          if (index >= 0) {
            newValue.splice(index, 1)
            onRowSelectionChange(newValue)
          } else {
            newValue.push(id)

            onRowSelectionChange(newValue)
          }
        }
      }
    },
    [selection, onRowSelectionChange, isSelectSingleRow]
  )

  const onExpandToggleHandler = useCallback(
    (id: TableRowId) => {
      if (expandedRows && onExpandChange) {
        const index = expandedRows.indexOf(id)
        const newValue = [...expandedRows]
        if (index >= 0) {
          newValue.splice(index, 1)
          onExpandChange(newValue)
        } else {
          newValue.push(id)
          onExpandChange(newValue)
        }
      }
    },
    [expandedRows, onExpandChange]
  )

  const getGroupExpandClickHandler = useCallback(
    (data: GroupedTableData<T>) => () => {
      if (expandedGroups && onExpandedGroupsChange) {
        const index = expandedGroups.indexOf(data.key)
        const newValue = [...expandedGroups]
        if (index >= 0) {
          newValue.splice(index, 1)
          onExpandedGroupsChange(newValue)
        } else {
          newValue.push(data.key)
          onExpandedGroupsChange(newValue)
        }
      }
    },
    [expandedGroups, onExpandedGroupsChange]
  )

  const isRowChecked = useCallback(
    (id: TableRowId) => {
      if (selection) {
        return selection.includes(id)
      }
    },
    [selection]
  )

  const isRowExpanded = useCallback(
    (id: TableRowId) => {
      if (expandedRows) {
        return expandedRows.includes(id)
      }
    },
    [expandedRows]
  )

  const handleSelectAll = useCallback(() => {
    if (onSelectionChange) {
      if (selectionAllState === true) {
        onSelectionChange([])
      } else if (data) {
        onSelectionChange(data.map((item, index) => idExtractor(item, index)))
      } else if (groupedData) {
        onSelectionChange(
          groupedData.reduce<TableRowId[]>((acc, item, indexA) => {
            acc.push(...item.data.map((item, indexB) => idExtractor(item, (indexA + 1) * indexB)))
            return acc
          }, [])
        )
      }
    }
  }, [onSelectionChange, selectionAllState, data, groupedData, idExtractor])

  const selectAllNode = useMemo(() => {
    if (selectionAllState !== undefined) {
      return (
        <th className='align-middle w-30px'>
          <TableSelectioncheckbox
            disabled={hideSelectAll}
            checked={selectionAllState}
            onChange={handleSelectAll}
          />
        </th>
      )
    }
    return null
  }, [selectionAllState, hideSelectAll, handleSelectAll])

  const isGroupSelected = useCallback(
    (group: GroupedTableData<T>) => {
      if (selection && group.data.length) {
        let allSelected = true
        let someSelected = false

        group.data.forEach((item, index) => {
          const id = idExtractor(item, index)
          const selected = selection.includes(id)
          if (selected) {
            someSelected = true
          } else {
            allSelected = false
          }
        })

        if (allSelected) {
          return true
        }
        if (someSelected) {
          return null
        }
      }
      return false
    },
    [idExtractor, selection]
  )

  const getGroupSelectHandler = useCallback(
    (group: GroupedTableData<T>) => {
      if (selection && onSelectionChange) {
        return () => {
          const ids = group.data.map((item, index) => idExtractor(item, index))
          if (isGroupSelected(group)) {
            onSelectionChange(selection.filter((item) => !ids.includes(item)))
          } else {
            const newIds = ids.reduce(
              (acc, id) => {
                const isSelected = acc.some((selectedItem) => selectedItem === id)
                if (!isSelected) {
                  acc.push(id)
                }
                return acc
              },
              [...selection]
            )
            onSelectionChange(newIds)
          }
        }
      }
    },
    [selection, onSelectionChange, isGroupSelected, idExtractor]
  )

  const emptyRow = useMemo(() => {
    let colSpan = columns.length
    if (actions) {
      colSpan++
    }
    if (selectAllNode) {
      colSpan++
    }
    return (
      <tr style={{pointerEvents: 'none'}}>
        <td colSpan={colSpan} className='py-10'>
          No records found
        </td>
      </tr>
    )
  }, [actions, columns.length, selectAllNode])

  const groupingColumns = useMemo(
    () => columns.filter((c) => c && !isColumnHidden(c.field) && grouping !== c.field),
    [columns, grouping, isColumnHidden]
  )

  const getGroupedRowColumn = useCallback(
    (rowIndex: number) => {
      return groupingColumns[rowIndex]
    },
    [groupingColumns]
  )

  const tableBody = useMemo(() => {
    if (data?.length) {
      return data.map((data, index) => {
        const id = idExtractor(data, index)
        const className = typeof classes?.row === 'function' ? classes.row(data) : classes?.row
        return (
          <TableRow
            className={className}
            hideExpansion={isExpansionHidden ? isExpansionHidden(data) : !expandedRows}
            disableCheckbox={isSelectDisabled?.(data)}
            expanded={isRowExpanded(id)}
            expansion={expansion}
            hiddenColumns={hiddenColumns}
            key={id}
            id={id}
            columns={columns}
            data={data}
            onExpandToggle={onExpandToggleHandler}
            onSelect={onSelectHandler}
            onRowSelect={handleonRowSelect}
            actions={actions}
            checked={isRowChecked(id)}
            isAllCheckBoxDisabled={isAllCheckBoxDisabled}
          />
        )
      })
    } else if (groupedData?.length && expandedGroups && grouping) {
      return groupedData.map((groupData, index) => {
        let paddingColumnCount = Math.max(
          columns.filter((c) => !isColumnHidden(c.field)).length - 1,
          0
        )
        const isGroupExpanded = expandedGroups.includes(groupData.key)

        if (!actions && !isFromFilterTable) {
          paddingColumnCount--
        } else if (!actions && isFromFilterTable) {
          paddingColumnCount++
        }

        const groupedColumn = columns.find((c, i) => c.field === groupData.field)
        const GroupedColumnRender = groupedColumn?.groupedColumnRender

        return (
          <Fragment key={groupData.key}>
            <tr>
              {selection && (
                <td>
                  <TableSelectioncheckbox
                    checked={isGroupSelected(groupData)}
                    onChange={getGroupSelectHandler(groupData)}
                  />
                </td>
              )}
              <td>
                {groupData.data.length ? (
                  <MetronicIconButton
                    iconType='Navigation'
                    iconName={isGroupExpanded ? 'Angle-down' : 'Angle-right'}
                    size='sm'
                    variant='text'
                    color='primary'
                    onClick={getGroupExpandClickHandler(groupData)}
                  />
                ) : null}
                {/* <MetronicIconButton
                  iconType='Navigation'
                  iconName={isGroupExpanded ? 'Angle-down' : 'Angle-right'}
                  size='sm'
                  variant='text'
                  color='primary'
                  onClick={getGroupExpandClickHandler(groupData)}
                /> */}
              </td>
              <td className='text-nowrap'>
                {GroupedColumnRender ? (
                  <GroupedColumnRender groupData={groupData} />
                ) : (
                  groupData.label
                )}
              </td>
              {new Array(paddingColumnCount).fill(null).map((_, i) => {
                const column = getGroupedRowColumn(i + 1)
                const GroupedColumnRender = column?.groupedColumnRender
                const isActionsColumn = i + 1 === paddingColumnCount && Boolean(groupRowActions)

                if (isFromFilterTable) {
                  if (GroupedColumnRender) {
                    return (
                      <td key={i}>
                        {isActionsColumn && (
                          <div className='d-flex justify-content-end align-items-center flex-nowrap'>
                            {groupRowActions?.(groupData)}
                          </div>
                        )}
                        {GroupedColumnRender && <GroupedColumnRender groupData={groupData} />}
                      </td>
                    )
                  } else {
                    return null
                  }
                } else {
                  return (
                    <td key={i}>
                      {isActionsColumn && (
                        <div className='d-flex justify-content-end align-items-center flex-nowrap'>
                          {groupRowActions?.(groupData)}
                        </div>
                      )}
                      {GroupedColumnRender && <GroupedColumnRender groupData={groupData} />}
                    </td>
                  )
                }
              })}
            </tr>
            {groupData.data.length
              ? isGroupExpanded &&
                groupData.data.map((data) => {
                  const id = idExtractor(data, index)
                  return (
                    <TableRow
                      hideExpansion={isExpansionHidden ? isExpansionHidden(data) : !expandedRows}
                      disableCheckbox={isSelectDisabled?.(data)}
                      expanded={isRowExpanded(id)}
                      paddingStart={1}
                      expansion={expansion}
                      hiddenColumns={hiddenColumns}
                      key={id}
                      id={id}
                      columns={headerColumns}
                      data={data}
                      onExpandToggle={onExpandToggleHandler}
                      onSelect={onSelectHandler}
                      actions={actions}
                      checked={isRowChecked(id)}
                      groupData={groupData}
                      isAllCheckBoxDisabled={isAllCheckBoxDisabled}
                    />
                  )
                })
              : null}
            {/* {isGroupExpanded &&
              groupData.data.map((data) => {
                const id = idExtractor(data, index)
                return (
                  <TableRow
                    hideExpansion={isExpansionHidden ? isExpansionHidden(data) : !expandedRows}
                    disableCheckbox={isSelectDisabled?.(data)}
                    expanded={isRowExpanded(id)}
                    paddingStart={1}
                    expansion={expansion}
                    hiddenColumns={hiddenColumns}
                    key={id}
                    id={id}
                    columns={headerColumns}
                    data={data}
                    onExpandToggle={onExpandToggleHandler}
                    onSelect={onSelectHandler}
                    actions={actions}
                    checked={isRowChecked(id)}
                    groupData={groupData}
                    isAllCheckBoxDisabled={isAllCheckBoxDisabled}
                  />
                )
              })} */}
          </Fragment>
        )
      })
    }
    return emptyRow
  }, [
    actions,
    classes,
    columns,
    data,
    emptyRow,
    expandedGroups,
    expandedRows,
    expansion,
    getGroupExpandClickHandler,
    getGroupSelectHandler,
    getGroupedRowColumn,
    groupRowActions,
    groupedData,
    grouping,
    headerColumns,
    hiddenColumns,
    idExtractor,
    isColumnHidden,
    isExpansionHidden,
    isGroupSelected,
    isRowChecked,
    isRowExpanded,
    isSelectDisabled,
    onExpandToggleHandler,
    onSelectHandler,
    selection,
    handleonRowSelect,
    isAllCheckBoxDisabled,
  ])

  const getSortHandler = useCallback(
    (column: string, isAscending?: boolean) => {
      if (onSort) {
        return () => {
          onSort && onSort([column, !isAscending])
        }
      }
    },
    [onSort]
  )

  const handleColumnHiddenChange = useCallback(
    (column: string) => {
      if (onHiddenColumnsChange && hiddenColumns) {
        const newValues = [...hiddenColumns]
        const columnIndex = newValues.indexOf(column)
        if (columnIndex >= 0) {
          newValues.splice(columnIndex, 1)
        } else {
          newValues.push(column)
        }
        onHiddenColumnsChange(newValues)
      }
    },
    [hiddenColumns, onHiddenColumnsChange]
  )

  const groupDataHasData = useMemo(() => {
    if (groupedData?.length) {
      const hasData = groupedData.find((groupData) => {
        if (groupData.data.length) {
          return groupData
        }
      })
      if (hasData) {
        return true
      }
    }
    return false
  }, [groupedData])

  const tableHeaders = useMemo(() => {
    const headers = headerColumns.reduce<ReactNode[]>((acc, column, index) => {
      const isAscending = isColumnAscending(column.sortField || column.field)
      const isHidden = isColumnHidden(column.field) && grouping !== column.field
      if (!isHidden) {
        acc.push(
          <TableHeader
            align={column.cellStyle?.align}
            key={column.field}
            label={column.label}
            className={clsx(
              index === 0 && !selectAllNode && !groupDataHasData && 'ps-5',
              classes?.header,
              column.className,
              column.classes?.header
            )}
            isAscending={isAscending}
            onSort={
              column.sortable
                ? getSortHandler(column.sortField || column.field, isAscending)
                : undefined
            }
          />
        )
      }
      return acc
    }, [])

    return (
      <>
        {selectAllNode}
        {grouping ? groupDataHasData ? <th className='w-50px' /> : <th className='w-10px' /> : null}
        {/* {grouping && <th className='w-50px' />} */}
        {expansion && <th />}
        {headers}
        {actions && <TableHeader className={classes?.header} align='end' label='Actions' />}
      </>
    )
  }, [
    headerColumns,
    selectAllNode,
    grouping,
    expansion,
    actions,
    classes?.header,
    isColumnAscending,
    isColumnHidden,
    getSortHandler,
    groupDataHasData,
  ])

  const columnHideDrawer = useMemo(() => {
    if (columns && hiddenColumns) {
      return (
        <TableHiddenColumnDrawerMenu
          columns={columns}
          onChange={handleColumnHiddenChange}
          hidden={hiddenColumns}
          drawerRef={setHideColumnDrawer}
        />
      )
    }
    return null
  }, [columns, handleColumnHiddenChange, hiddenColumns, setHideColumnDrawer])

  return (
    <>
      <div className={clsx(`card card-flush mb-5`, className, classes?.root)}>
        {title && <h5 className='card-title mt-10 ms-10 mb-0'>{title}</h5>}
        <TableActionHeader
          left={leftToolbar}
          right={rightToolbar}
          onOptionClick={hideColumnDrawer?.show}
          noPadding={noPadding}
        />
        <div className='card-body pt-0'>
          {body}
          <div className='row g-5'>
            <div className='col'>
              <div className='table-responsive'>
                <table
                  className={clsx(
                    'table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4 table-highlighted',
                    classes?.table
                  )}
                >
                  <thead>
                    <tr className='fw-bolder text-muted'>{tableHeaders}</tr>
                  </thead>
                  <tbody>{tableBody}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {columnHideDrawer}
    </>
  )
}
